import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import Section from "../Section"

import styles from "./styles.module.css"

const Project = ({ title, children }) => {
  return (
    <Section type="fluid">
      <Section.Content className="max-w-3xl">
        <h1 className="text-center text-4xl font-bold">{title}</h1>
        <div
          className={classnames(
            styles.content,
            "mt-10 mb-10 leading-loose text-lg"
          )}
        >
          {children}
        </div>
      </Section.Content>
    </Section>
  )
}

Project.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default Project
