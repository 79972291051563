import React from "react"
import PropTypes from "prop-types"

import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Project from "../components/Project"

const Template = ({ data, location }) => {
  const {
    mdx: { frontmatter, body },
  } = data

  return (
    <Layout location={location}>
      <SEO title={frontmatter.title} />
      <Project {...frontmatter}>
        <MDXRenderer>{body}</MDXRenderer>
      </Project>
    </Layout>
  )
}

Template.propTypes = {
  data: PropTypes.object,
  location: PropTypes.string,
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        path
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Template
